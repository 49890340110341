#content {
  position: relative;
  overflow-x: hidden;
  text-align: center;
  min-height: 100vh;
  mix-blend-mode: difference; 
  overflow: hidden;
}

#content * {
  z-index: 2;
}

#content h1 {
  font-size: xxx-large;
}

#content p {
  font-weight: 200;
  font-size: larger;
}

#content::after {
  content: "";
  position: absolute;
  left: min(-200px, -25vw);
  top: min(-200px, -25vw);
  width: 70vw;
  height: 70vw;
  max-width: 800px;
  max-height: 800px;
  background-color: var(--my_orange);
  border-radius: 100%;
  z-index: 0 !important;
}

#content::before {
  content: "";
  position: absolute;
  right: min(-200px, -25vw);
  bottom: min(-200px, -25vw);
  width: 70vw;
  height: 70vw;
  max-width: 800px;
  max-height: 800px;
  background-color: var(--my_orange);
  border-radius: 100%;
  z-index: 0 !important;
}

#content .logo {
  background: var(--my_black);
  border-radius: 10px;
  padding: 20px;
  width: 80px;
}

#content .navigator {
  display: flex;
  place-content: space-between;
}

#content .navigator h6 {
  border-bottom: 2px solid var(--my_orange);
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  transition: 0.3s;
}

#content .navigator h6:hover {
  transform: translateY(-5px);
}

#content .navigator h6:active {
  transform: scale(0.9);
}

#content .description {
  white-space: pre-line;
}