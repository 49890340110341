:root {
  --my_black: #0E0E0E;
  --my_orange: #FF5C00;
  --text_color: white;
  --my_gray: #373737;
}

html {
  font-family: 'Raleway', sans-serif; 
}

body {
  background: var(--my_black);
  color: white;
}

button {
  background-color: var(--my_orange);
  border-radius: 6px;
  border: none;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: var(--text_color);
  font-weight: 600;
  box-shadow: 0px 2px 4px rgba(0,0,0,0.3);
}

.centered {
  display: grid;
  place-content: center;
  outline: none;
  border: none;
  box-shadow: none;
}

.separator {
  width: 20px;
  height: 4px;
  background-color: var(--my_orange);
  border-radius: 4px;
  mix-blend-mode: difference;
}

.my_chip {
  border: 1px solid var(--my_orange) !important;
  margin: 5px !important;
  color: var(--text_color) !important;
  background: var(--my_black) !important;
  box-shadow: 0px 2px 4px rgba(0,0,0,0.3);
}

.my_chip.selected {
  background: var(--my_orange) !important;
}

.my_chip:hover {
  cursor: pointer;
}

.full-height {
  min-height: 100vh;
}

.MuiRadio-root {
  color: var(--text_color) !important;
}

.MuiFormControl-root {
  width: 100%;
}

.block {
  background: var(--my_gray);
  border-radius: 10px;
  padding: 20px;
  mix-blend-mode: normal;
}

.block .separator {
  mix-blend-mode: normal;
}